.loading {
  background-color: var(--background--light);
  border-bottom: var(--px1) solid var(--border);
  grid-area: header;
  padding: 1.25rem;
}

.loading__indicator {
  background-color: var(--background--text);
  border-radius: var(--border-radius);
  color: var(--primary);
  display: inline-block;
  line-height: 2.5rem;
  text-align: center;
  width: 2.5rem;
}
