
.section {
  padding: 0;
  max-width: 50rem;
}

.section__inner {
  padding: 1rem 1.5rem;
}

.section__relations + .section__inner {
  padding-top: 0.5rem;
}

.section__text {
  background-color: var(--background--text);
  border-radius: var(--border-radius);
  padding: 1rem 1.5rem;
}

.section__actions {
  padding: 0.5rem 0 0;
  text-align: right;
}

.section__relations {
  padding: 1.5rem;
  padding-bottom: 0;
}
