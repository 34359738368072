
.relation {
  margin: 1.0rem 0;
}

.relation__inner {
  border-left: 0.25rem solid var(--background--dark);
  padding: 0.5rem 1.5rem;
  /*transition: background-color 0.1s ease-out, border-color 0.1s ease-out;*/
}

.relation__info {
  align-items: center;
  display: flex;
}

.relation__icon {
  color: var(--primary);
  flex: 0 0 auto;
  font-size: 1.2rem;
  line-height: 2rem;
  width: 2rem;
}

.relation__text {
  color: var(--text--medium);
  flex: 0 0 auto;
  line-height: 2rem;
  padding-right: 0.5rem;
}

.relation__topic {
  flex: 0 0 auto;
  line-height: 2rem;
}

.relation__actions {
  height: 0;
  overflow: hidden;
  padding-left: 2rem;
  /*transition: height 0.1s ease-out;*/
}

.relation--selected .relation__inner {
  background-color: var(--selection);
  border-color: var(--primary);
}

.relation--selected.relation--remove .relation__inner {
  border-color: var(--danger);
}

.relation--selected .relation__actions {
  height: 2rem;
}
