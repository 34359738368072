.topic-badge {
  font-size: 1rem;
  line-height: 2rem;
  cursor: pointer;
}

.topic-badge__icon {
  color: var(--primary);
  display: inline-block;
  width: 1.5rem;
}

.topic-badge__name {
  color: var(--text--medium);
  cursor: pointer;
}

.topic-badge__group .topic-badge + .topic-badge {
  margin-left: 2rem;
}
