.topic-relation {
  background-color: var(--background--text);
  border-radius: var(--border-radius);
  display: flex;
  margin-bottom: var(--px1);
}

.topic-relation--empty {
  border: var(--px1) dashed var(--background--light);
  border-radius: var(--border-radius);
  color: var(--background--light);
  font-style: italic;
  line-height: calc(3rem - 2 * var(--px1));
  margin-bottom: var(--px1);
  text-align: center;
}

.topic-relation > * {
  display: inline-block;
  flex: 0 0 auto;
  line-height: 3rem;
}

.topic-relation__icon {
  color: var(--text--medium);
  font-size: 1.5rem;
  min-width: 3rem;
  padding-right: 0.5rem;
  text-align: center;
}

.topic-relation--selected .topic-relation__icon {
  color: var(--primary);
}

.topic-relation__action {
  border-left: var(--px1) solid var(--background--dark);
  font-size: 1.5rem;
  min-width: 3rem;
  text-align: center;
}

span.topic-relation__name {
  flex-grow: 1;
}

.relation-list-editor label {
  color: var(--text--medium);
  display: block;
  line-height: 1.5rem;
  padding: 1.5rem;
}

.relation-list-editor__selected-topics,
.relation-list-editor__not-selected-topics {
  border-bottom: var(--px1) solid var(--border);
  padding: 0 1.5rem 1.5rem;
}
