
.button {
  display: flex;
  justify-content: center;
  background-color: var(--background--dark);
  border-radius: var(--border-radius);
  cursor: pointer;
}

.button + .button,
.button-group + .button,
.button + .button-group {
  margin-left: 0.5rem;
}

.button--dark {
  background-color: var(--background--dark);
}

.button--primary {
  background-color: var(--primary);
}

.button--active .button__content {
  color: var(--primary);
}


.button__content {
  flex: 0 0 auto;
  min-width: 2.5rem;
  text-align: center;
}

.button__content--icon-text {
  padding-right: 1rem;
}

.button__content--text-only {
  padding: 0 1rem;
}

.button__icon.fa {
  font-size: 1.5rem;
  line-height: 2.5rem;
  min-width: 2.5rem;
}

.button__text {
  line-height: 2.5rem;
}

.button-group {
  display: flex;
}

.button-group--padding {
  padding: 1.25rem 1.5rem;
}

.button-group .button {
  border-radius: 0;
  flex: 1 0 auto;
  margin: 0;
}

.button-group .button:first-child {
  border-top-left-radius: var(--px4);
  border-bottom-left-radius: var(--px4);
}

.button-group .button:last-child {
  border-top-right-radius: var(--px4);
  border-bottom-right-radius: var(--px4);
}
