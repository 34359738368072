.project-tab {
  --border-width: calc(1rem / 6);
  border-top: var(--border-width) solid transparent;
  display: inline-block;
  font-weight: 500;
  line-height: calc(5rem - (var(--border-width) * 2));
  padding: 0 1.5rem var(--border-width);
  text-transform: uppercase;
}

a.project-tab:link,
a.project-tab:visited {
  color: var(--text--medium);
}

a.project-tab:hover {
  color: var(--text--light);
}

a.project-tab:active,
a.project-tab.project-tab--active {
  border-color: var(--primary);
  color: var(--text--light);
}

.project-tab__container {
  background-color: var(--background--light);
  border-bottom: var(--px1) solid var(--border);
  grid-area: header;
  padding-left: 1rem;
}
