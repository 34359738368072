* {
  box-sizing: border-box;
}

html {
  font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
  font-size: 75%;
  margin: 0;
  height: 100%;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}

body {
  background-color: var(--background--dark);
  color: var(--text--light);
  font-size: var(--f14);
  margin: 0;
  height: 100%;
  padding: 0;
  padding-top: 2rem;
}

button {
  border: 0;
  color: inherit;
  font: inherit;
  font-size: var(--f14);
  margin: 0;
  padding: 0;
  outline: 0;
}

input,
textarea {
  border: 0;
  border-radius: 0;
  color: var(--text--light);
  font: inherit;
  font-size: var(--f14);
  margin: 0;
  outline: 0;
}

a {
  text-decoration: none;
}

a:link,
a:active {
  color: inherit;
}

a:visited {
  color: inherit;
}

p {
  font-size: var(--f14);
  hyphens: auto;
  line-height: 1.5;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-weight: normal;
}

::-webkit-scrollbar {
  background: var(--border);
  width: var(--px1);
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, transparent, var(--primary) 50%, transparent);
}
