.separator {
  --height: 1.5rem;
  padding: 0 1.5rem;
  min-height: var(--height);
  position: relative;
}

.separator:last-child {
  min-height: calc(0.75rem + var(--px1));
}

.separator::before {
  background-color: var(--border);
  left: 0;
  right: 0;
  top: calc(var(--height) / 2);
  content: '';
  height: var(--px1);
  position: absolute;
  z-index: 0;
}

.separator__text {
  background-color: var(--background--dark);
  color: var(--text--medium);
  font-size: 1rem;
  line-height: var(--height);
  padding: 0 0.5rem;
  position: relative;
  text-transform: uppercase;
  z-index: 1;
}

.separator--slim {
  --height: var(--px1);
}
