.relation-list-editor label {
  color: var(--text--medium);
  display: block;
  line-height: 1.5rem;
  padding: 1.5rem;
}

.relation-list-editor__selected-topics,
.relation-list-editor__not-selected-topics {
  border-bottom: var(--px1) solid var(--border);
  padding: 0 1.5rem 1.5rem;
}
