.action {
  background: transparent;
  color: var(--primary);
  cursor: pointer;
  display: inline;
  font-size: 1rem;
  line-height: 2rem;
  outline: 0;
}

.action__separator + .action {
  padding-left: 1rem;
}

.action--delete {
  color: var(--danger);
}

.action--cancel {
  color: var(--text--medium);
}

.action__group {
  display: inline-flex;
  align-items: center;
}

.action__group .action {
  flex: 0 0 auto;
  padding-right: 1rem;
}

.action__separator {
  background-color: var(--border);
  min-height: 1rem;
  max-height: 1rem;
  min-width: var(--px1);
}
