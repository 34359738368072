.topic-relation-editor label {
  color: var(--text--medium);
  display: block;
  line-height: 1.5rem;
  padding: 1.5rem;
}

.topic-relation-editor__selected-topic,
.topic-relation-editor__not-selected-topics {
  border-bottom: var(--px1) solid var(--border);
  padding: 0 1.5rem 1.5rem;
}

.topic-relation-editor__filter {
  background-color: var(--background--light);
  border: 0;
  border-radius: var(--border-radius);
  display: block;
  line-height: 1.5rem;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  outline: 0;
  width: 100%;
}

.topic-relation-editor__filter::placeholder {
  font-style: italic;
}
