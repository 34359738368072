.app {
  display: grid;
  grid-template-columns: 24rem 24rem auto;
  grid-template-rows: calc(5rem + var(--px1)) auto;
  grid-template-areas: "logo header header"
                       "types topics topic";
  min-height: calc(100vh - 2rem);
}

.empty {
  background-color: var(--background--dark);
}
