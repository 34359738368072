
.topic {
  padding: 0;
}

.topic__inner {
  border-left: 0.25rem solid var(--background--dark);
  padding: 1rem 1.5rem;
  /*transition: background-color 0.1s ease-out, border-color 0.1s ease-out;*/
}

.topic__info {
  align-items: center;
  display: flex;
}

.topic__icon {
  color: var(--text--medium);
  flex: 0 0 auto;
  font-size: 1.5rem;
  line-height: 2rem;
  width: 2.5rem;
}

.topic__name {
  flex: 1 0;
  line-height: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.topic__actions {
  height: 0;
  overflow: hidden;
  /*transition: height 0.1s ease-out;*/
}

.topic--selected .topic__inner,
.topic--remove .topic__inner {
  background-color: var(--selection);
  border-color: var(--primary);
}

.topic--remove .topic__inner,
.topic--selected.topic--remove .topic__inner {
  border-color: var(--danger);
}

.topic--selected .topic__actions,
.topic--remove .topic__actions {
  height: 2rem;
}


.topic + .separator {
  margin-top: 1rem;
}

.separator + .topic {
  margin-top: 1rem;
}
