.relation-editor__container {
  border-right: var(--px1) solid var(--border);
  max-width: 50rem;
}

.relation-editor__topic {
  padding: 1.5rem;
}

.relation-editor__topic label {
  color: var(--text--medium);
  display: block;
  padding-bottom: 1.5rem;
}
