
.input {
  border-bottom: var(--px1) solid var(--border);
  position: relative;
  padding: 1.5rem 1.5rem;
}

.input label {
  color: var(--text--medium);
  display: block;
  line-height: 1.5rem;
  padding-bottom: 0.5rem;
}

.input input,
.input textarea {
  background-color: transparent;
  border: 0;
  border-bottom: var(--px1) solid var(--text--medium);
  line-height: 2.5rem;
  outline: 0;
  padding-bottom: 0.5rem;
  width: 100%;
}

.input.input--text-area textarea {
  line-height: 2rem;
}

.input input::selection {
  background-color: var(--primary);
}

.input input::placeholder {
  color: var(--text--medium);
}

.input input:focus {
  border-color: var(--primary);
  outline: 0;
}

.input--icon input {
  padding-left: 2.5rem;
}

.fa.input__icon {
  color: var(--primary);
  font-size: 1.333333rem;
  left: 1.75rem;
  line-height: 2.5rem;
  position: absolute;
}

.input--radio__container,
.input--checkbox__container {
  padding-top: 0.6rem;
}

.input--radio__option,
.input--checkbox__option {
  align-items: center;
  display: flex;
  line-height: 2.5rem;
}

.input--radio__option .fa,
.input--checkbox__option .fa {
  color: var(--text--medium);
  font-size: 1.25rem;
  padding-right: 1rem;
}

.input--radio__option--selected .fa,
.input--checkbox__option--selected .fa {
  color: var(--text--light);
}
