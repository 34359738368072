
.type {
  padding: 0;
}

.type__inner {
  border-left: 0.25rem solid var(--background--dark);
  padding: 1rem 1.5rem;
  /*transition: background-color 0.1s ease-out, border-color 0.1s ease-out;*/
}

.type__info {
  align-items: center;
  display: flex;
}

.type__icon {
  color: var(--text--medium);
  flex: 0 0 auto;
  font-size: 1.5rem;
  line-height: 2rem;
  width: 2.5rem;
}

.type__name {
  flex: 1 0 auto;
  line-height: 2rem;
}

.type__actions {
  height: 0;
  overflow: hidden;
  padding-left: 2.5rem;
  /*transition: height 0.1s ease-out;*/
}

.type--selected .type__inner {
  background-color: var(--selection);
  border-color: var(--primary);
}

.type--selected.type--remove .type__inner {
  border-color: var(--danger);
}

.type--selected .type__actions {
  height: 2rem;
}
