
.small-device {
  display: none;
}

@media only screen and (max-width: 1088px) {

  .types-column {
    display: none;
  }

  .types-column.show-types-column {
    display: initial;
  }

  .topics-column {
    grid-area: types;
  }

  .topic-column {
    grid-area: 2 / 2 / span 1 / span 2;
  }

  .small-device {
    display: initial;
  }
}
