.login {
  background-color: var(--background--light);
  border-bottom: var(--px1) solid var(--border);
  grid-area: header;
  padding: 1.25rem;
}

.login__form {
  display: flex;
}

.login__form > * {
  flex: 0 0 auto;
}

.login__form input {
  background-color: var(--background--text);
  border-radius: var(--border-radius);
  display: inline-block;
  margin-right: 0.5rem;
  padding: 0 1rem;
}

.login__form input::placeholder {
  font-style: italic;
}
