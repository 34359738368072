.logo {
  background-color: var(--background--light);
  border-right: var(--px1) solid var(--border);
  border-bottom: var(--px1) solid var(--border);
  color: var(--primary);
  grid-area: logo;
  font-family: 'Marck Script';
  font-size: 3rem;
  line-height: 5rem;
  text-align: right;
  padding: 0 2.5rem;
}
