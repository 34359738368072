
.count {
  background-color: var(--background--light);
  border-radius: 0.75rem;
  display: inline-block;
  font-size: 1rem;
  line-height: 1.5rem;
  height: 1.5rem;
  text-align: center;
  width: 2.5rem;
}
