.editor-header {
  background-color: var(--background--light);
}

.editor-header__inner {
  display: flex;
  justify-content: space-between;
  padding: 1.25rem 1.5rem;
  max-width: 50rem;
}
.editor-header--left .editor-header__inner {
  justify-content: flex-start;
}

.editor-header__inner > * {
  flex: 0 0 auto;
}
