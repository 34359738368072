.list-header {
  background-color: var(--background--light);
  overflow: hidden;
  margin-bottom: 1rem;
  min-height: 5rem;
}

.list-header.list-header--no-margin {
  margin-bottom: 0;
}

.list-header__inner {
  display: grid;
  grid-template-columns: 100% 100%;
  grid-template-rows: auto;
  grid-template-areas: "main filter";
  transform: translate(0%);
  max-width: 50rem;
  /*transition: transform 0.3s ease;*/
}

.list-header__buttons {
  display: flex;
  grid-area: main;
  padding: 1.25rem 1.5rem;
}

.list-header__buttons .list-header__buttons__filler {
  flex-grow: 1;
}

.list-header__buttons > * {
  flex: 0 0 auto;
}

.list-header__filter {
  display: flex;
  grid-area: filter;
  padding: 1.25rem 1.5rem;
}

.list-header__filter > * {
  flex: 0 0 auto;
}

.list-header__filter__input {
  background-color: var(--background--dark);
  border: 0;
  border-radius: var(--border-radius);
  display: block;
  flex-grow: 1;
  line-height: 1.5rem;
  margin-right: 0.5rem;
  padding: 0.5rem 1rem;
  outline: 0;
}

.list-header__filter__input::placeholder {
  font-style: italic;
}

.list-header--filter .list-header__inner {
  transform: translate(-100%);
}
