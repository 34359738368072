* {
  --background--light: hsl(196, 9%, 23%);
  --background--dark: hsl(207, 10%, 17%);
  --background--text: hsl(207, 9%, 19%);
  --border: rgb(46,53,56);
  --primary: #fc0;
  --text--light: hsl(180, 17%, 98%);
  --text--medium: hsl(206, 11%, 40%);
  --selection: hsl(202, 11%, 14%);
  --danger: hsl(20, 100%, 43%);


  --progress: linear-gradient(to right, transparent, var(--primary) 50%, transparent 100%);

  --f14: 1.16667rem;

  --px1: calc(1rem / 12);
  --px2: calc(1rem / 6);
  --px4: calc(1rem / 3);

  --border-radius: calc(1rem / 3);

  --breakpoint: 1200px;
}

.light-theme,
.light-theme * {
  --background--light: hsl(196, 9%, 90%);
  --background--dark: hsl(207, 9%, 98%);
  --background--text: hsl(207, 9%, 90%);
  --border: hsl(207, 9%, 92%);
  --primary: hsl(192, 100%, 43%);
  --text--light: hsl(180, 17%, 12%);
  --text--medium: hsl(206, 11%, 60%);
  --selection: hsl(202, 11%, 90%);
}
